import moment from "moment/moment";
import createPersistedState from "use-persisted-state";
import { isEmpty } from "lodash";

import { COURSES_LIST } from "../constant/mockdata";

const useInterstrideState = createPersistedState("interstride");

const initialArgs = {
  users: null,
  users_local: null,
  preTestStep: 1,
  currentTestStep: -1,
  testType: null,
  testQuestionsList: [],
  testQuestionsLabels: [],
  testAnswersList: [],
  isCompletedTest: false,
  companies: [],
  appliedVisaFilters: {},
  selectedCompany: null,
  jobsCountryList: [],
  jobsCountryListVersion: null,
  selectedJob: null,
  activeProfileStep: 0,
  firstLoginProfilePopup: null,
  permissions: {},
  permissionsExpiry: null,
  /// All Notifications Start ///
  unread_notification_count: 0,
  topics_notifcations_count: 0,
  featured_job_notifications_count: 0,
  employer_job_notifications_count: 0,
  topic_specific_notifcations_count: [],
  chat_notifcations_count: 0,
  chat_specific_notifcations_count: [],
  deal_notifications_count: 0,
  webinar_notifications_count: 0,
  video_notifcations_count: 0,
  /// All Notifications End ///
  favoriteUsers: [],
  appliedFromJobAlerts: false,
  appointmentType: null,
  meetingPurposes: [],
  userProfileAttachement: null,
  ambassadorTutorial: false,
  studentViewPreference: "international",
};

const usePersistState = () => {
  const [persistState, setPersistState] = useInterstrideState(initialArgs);

  const updateUserPermissions = (updatedPermissions) => {
    const allowedCourses = COURSES_LIST.filter(
      (course) =>
        course.permission === true ||
        updatedPermissions[course.permission] === true
    );

    setPersistState((currentState) => ({
      ...currentState,
      permissions: {
        ...currentState.permissions,
        ...updatedPermissions,
        // Set menu permission based on courses permission
        courses: allowedCourses && !isEmpty(allowedCourses) ? true : false,
      },
      permissionsExpiry: moment().add(24, "hours").format("YYYY-MM-DD HH:mm"),
    }));
  };

  const updatePersistState = (data) => {
    setPersistState((currentState) => {
      return {
        ...currentState,
        ...data,
      };
    });
  };

  const updateUsersLocalState = (data) => {
    setPersistState((currentState) => {
      return {
        ...currentState,
        users_local: { ...currentState.users_local, ...data },
      };
    });
  };

  const updateUsersPersistState = (data) => {
    setPersistState((currentState) => {
      const latestUsersState = {
        ...currentState.users,
        ...data,
      };
      return {
        ...currentState,
        users: latestUsersState,
        users_local: latestUsersState,
        studentViewPreference: latestUsersState.student_view_preference,
      };
    });
  };

  const setUserProfileAttachment = (userProfileAttachement) => {
    setPersistState((currentState) => {
      return {
        ...currentState,
        userProfileAttachement,
      };
    });
  };

  const setPreTestStep = (preTestStep) => {
    setPersistState((currentState) => ({
      ...currentState,
      preTestStep,
      currentTestStep: preTestStep <= 2 ? -1 : currentState.currentTestStep,
    }));
  };

  const setTestType = (testType) => {
    setPersistState((currentState) => ({
      ...currentState,
      testType,
      currentTestStep: -1,
      testQuestionsList: [],
      testQuestionsLabels: [],
      testAnswersList: [],
      isCompletedTest: false,
    }));
  };

  const setTestQuestions = (questions) => {
    setPersistState((currentState) => ({
      ...currentState,
      testQuestionsList: questions,
      testQuestionsLabels: questions.map(({ category_name }) => category_name),
      isCompletedTest: false,
      currentTestStep: 0,
    }));
  };

  const updateTestData = (data) => {
    setPersistState((currentState) => ({
      ...currentState,
      ...data,
    }));
  };

  const resetTestState = () => {
    setPersistState((currentState) => ({
      ...currentState,
      preTestStep: 1,
      testType: null,
      currentTestStep: -1,
      testQuestionsList: [],
      testQuestionsLabels: [],
      testAnswersList: [],
      isCompletedTest: false,
    }));
  };

  const resumePreviousTest = () => {
    setPersistState((currentState) => ({
      ...currentState,
      isCompletedTest: false,
      currentTestStep:
        currentState.currentTestStep === -1 ? 0 : currentState.currentTestStep,
    }));
  };

  const setCompanies = (data) => {
    setPersistState((currentState) => ({
      ...currentState,
      companies: [...data],
    }));
  };

  const setAppliedVisaFilters = (data) => {
    setPersistState((currentState) => ({
      ...currentState,
      appliedVisaFilters: { ...data },
    }));
  };

  const setSelectedCompany = (data) => {
    setPersistState((currentState) => ({
      ...currentState,
      selectedCompany: { ...data },
    }));
  };

  const setAllNotifications = ({
    unread_notification_count,
    topics_notifcations_count,
    featured_job_notifications_count,
    employer_job_notifications_count,
    topic_specific_notifcations_count,
    chat_notifcations_count,
    chat_specific_notifcations_count,
    deal_notifications_count,
    webinar_notifications_count,
    video_notifcations_count,
  }) => {
    setPersistState((currentState) => ({
      ...currentState,
      unread_notification_count,
      topics_notifcations_count,
      featured_job_notifications_count,
      employer_job_notifications_count,
      topic_specific_notifcations_count,
      chat_notifcations_count,
      chat_specific_notifcations_count,
      deal_notifications_count,
      webinar_notifications_count,
      video_notifcations_count,
    }));
  };

  const resetAllNotifications = () => {
    setPersistState((currentState) => ({
      ...currentState,
      unread_notification_count: 0,
      topics_notifcations_count: 0,
      featured_job_notifications_count: 0,
      employer_job_notifications_count: 0,
      topic_specific_notifcations_count: [],
      chat_notifcations_count: 0,
      chat_specific_notifcations_count: [],
      deal_notifications_count: 0,
      webinar_notifications_count: 0,
      video_notifcations_count: 0,
    }));
  };

  const setLiveNotificationsTopic = ({
    topic_id,
    topic_notifications_count,
    total_notifications_count,
    total_topic_notifications_count,
  }) => {
    setPersistState((currentState) => {
      let _topic_specific_notifcations_count =
        currentState.topic_specific_notifcations_count;

      let index = _topic_specific_notifcations_count.findIndex(
        (element) => element[0] === topic_id
      );

      if (index === -1) {
        _topic_specific_notifcations_count.push([
          topic_id,
          topic_notifications_count,
        ]);
      } else {
        _topic_specific_notifcations_count[index][1] =
          topic_notifications_count;
      }

      return {
        ...currentState,
        unread_notification_count: total_notifications_count,
        topics_notifcations_count: total_topic_notifications_count,
        topic_specific_notifcations_count: _topic_specific_notifcations_count,
      };
    });
  };

  const setLiveNotificationsChat = ({
    chat_notifications_count,
    conversation_id,
    total_chat_notifications_count,
    total_notifications_count,
  }) => {
    setPersistState((currentState) => {
      let index = currentState.chat_specific_notifcations_count.findIndex(
        (element) => element[0] === conversation_id
      );
      if (index === -1) {
        currentState.chat_specific_notifcations_count.push([
          conversation_id,
          chat_notifications_count,
        ]);
      } else {
        currentState.chat_specific_notifcations_count[index][1] =
          chat_notifications_count;
      }
      return {
        ...currentState,
        unread_notification_count: total_notifications_count,
        chat_notifcations_count: total_chat_notifications_count,
      };
    });
  };

  const setLiveNotificationsEmployerJobs = ({
    total_notifications_count,
    employer_job_notifications_count,
  }) => {
    if (employer_job_notifications_count && total_notifications_count) {
      setPersistState((currentState) => ({
        ...currentState,
        unread_notification_count:
          currentState?.unread_notification_count +
          employer_job_notifications_count,
        total_notifications_count,
        employer_job_notifications_count,
      }));
    }
  };

  const setLiveNotificationsFeaturedJobs = ({
    total_notifications_count,
    featured_job_notifications_count,
  }) => {
    if (featured_job_notifications_count && total_notifications_count) {
      setPersistState((currentState) => ({
        ...currentState,
        unread_notification_count:
          currentState?.featured_job_notifications_count +
          featured_job_notifications_count,
        total_notifications_count,
        featured_job_notifications_count,
      }));
    }
  };

  const setJobsCountryList = (data, version) => {
    const optionData = [];
    data.forEach((option) => {
      optionData.push({ value: option.code, label: option.name });
    });
    optionData.sort((a, b) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    );
    setPersistState((currentState) => ({
      ...currentState,
      jobsCountryList: optionData,
      jobsCountryListVersion: version,
    }));
  };

  const setSelectedJob = (data) => {
    setPersistState((currentState) => ({
      ...currentState,
      selectedJob: { ...data },
    }));
  };

  const setActiveProfileStep = (activeProfileStep) => {
    setPersistState((currentState) => ({
      ...currentState,
      activeProfileStep,
    }));
  };

  const setFirstLoginProfilePopup = (firstLoginProfilePopup) => {
    setPersistState((currentState) => ({
      ...currentState,
      firstLoginProfilePopup,
    }));
  };

  const setFavoriteUsers = (favoriteUsers = []) => {
    setPersistState((currentState) => ({
      ...currentState,
      favoriteUsers: [...favoriteUsers],
    }));
  };

  const setAppliedFromJobAlerts = (data) => {
    setPersistState((currentState) => ({
      ...currentState,
      appliedFromJobAlerts: data,
    }));
  };
  const readChatSpecificNotification = (
    index,
    chat_notifcations_count,
    unread_notification_count
  ) => {
    setPersistState((currentState) => {
      if (currentState?.chat_specific_notifcations_count?.[index]?.length > 0)
        currentState.chat_specific_notifcations_count[index][1] = 0;
      return {
        ...currentState,
        unread_notification_count: unread_notification_count,
        chat_notifcations_count: chat_notifcations_count,
      };
    });
  };
  const decrementUnreadNotificationCount = () => {
    if (persistState.unread_notification_count > 0) {
      setPersistState((currentState) => ({
        ...currentState,
        unread_notification_count: currentState.unread_notification_count - 1,
      }));
    }
  };

  const setAppointmentType = (data) => {
    setPersistState((currentState) => ({
      ...currentState,
      appointmentType: data,
    }));
  };

  const setMeetingPurposes = (data) => {
    setPersistState((currentState) => ({
      ...currentState,
      meetingPurposes: data,
    }));
  };

  const setAmbassadorTutorial = (status) => {
    setPersistState((currentState) => ({
      ...currentState,
      ambassadorTutorial: status,
    }));
  };

  const updateUserProfileImage = (profile_image_url = "") => {
    setPersistState((currentState) => ({
      ...currentState,
      users: {
        ...currentState?.users,
        profile_image_url,
        user_profile_url: profile_image_url,
      },
      users_local: {
        ...currentState?.users_local,
        profile_image_url,
        user_profile_url: profile_image_url,
      },
    }));
  };

  return {
    ...persistState,
    updatePersistState,
    updateUsersLocalState,
    updateUsersPersistState,
    setUserProfileAttachment,
    updateTestData,
    setPreTestStep,
    setTestType,
    setTestQuestions,
    resetTestState,
    resumePreviousTest,
    setAppliedVisaFilters,
    setSelectedCompany,
    setCompanies,
    setJobsCountryList,
    setSelectedJob,
    setActiveProfileStep,
    setFirstLoginProfilePopup,
    updateUserPermissions,
    setAllNotifications,
    setLiveNotificationsTopic,
    setLiveNotificationsChat,
    setLiveNotificationsEmployerJobs,
    setLiveNotificationsFeaturedJobs,
    resetAllNotifications,
    setFavoriteUsers,
    setAppliedFromJobAlerts,
    setAppointmentType,
    setMeetingPurposes,
    readChatSpecificNotification,
    decrementUnreadNotificationCount,
    setAmbassadorTutorial,
    updateUserProfileImage,
    persistState,
  };
};

export default usePersistState;
