import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { Grid, Tooltip } from "@mui/material";
import debounce from "debounce-promise";

import {
  capitalizeFirstLetter,
  extractUniqueAndGroupJobs,
  processJobLocationTime,
} from "../../../helper/helper";
import {
  widgetSearchTopEmployerJobsAPI,
  widgetJobsCountryListAPI,
  widgetSearchJobsAPI,
} from "../../../services/widgetServices";
import usePromise from "../../../hooks/usePromise/usePromise";
import { widgetRedirection } from "../../../utils/common";
import {
  US_VISA_LIST,
  US_VISA_LIST_DOMESTIC_VIEW,
} from "../../../constant/mockdata";
import JobsStyles from "./JobsStyles";
import EmptyListWithImage from "../../../components/EmptyListWithImage/EmptyListWithImage";
import InitialAvatar from "../../../components/InitialAvatar/InitialAvatar";
import CardCustom from "../../../components/CardCustom/CardCustom";
import StarIcon from "../../../components/StarIcon/StarIcon";
import DropdownCustom from "../../../components/DropdownCustom/DropdownCustom";
import TextboxCustom from "../../../components/Textbox/TextboxCustom";
import JobsListingEffect from "../../../components/effects/JobsListingEffect/JobsListingEffect";
import WidgetOverlay from "../WidgetOverlay/WidgetOverlay";

import FilledFilterIcon from "../../../assets/svg/filled-filter-Icon.svg";
import FilterIcon from "../../../assets/svg/filter-Icon.svg";

const Jobs = ({ token }) => {
  const [jobs, setJobs] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [countryOptions, setCountryOptions] = useState([]);
  const [state, setState] = useState({
    keyword: null,
    country: "us",
    search_type: "top_1000_employers",
  });
  const [callSearchJobsAPI, refreshSearchJobsAPI] =
    usePromise(widgetSearchJobsAPI);
  const [callSearchTopEmployerJobsAPI, refreshSearchTopEmployerJobsAPI] =
    usePromise(widgetSearchTopEmployerJobsAPI);
  const [openFilter, toggleFilter] = useState(false);

  useEffect(() => {
    const getCountryOptions = async () => {
      try {
        const response = await widgetJobsCountryListAPI(token);
        if (response?.success && response?.countries) {
          const optionData = [];
          (response.countries ?? []).forEach((option) => {
            optionData.push({
              value: option.iso1.toLowerCase(),
              label: option.name,
            });
          });
          optionData.sort((a, b) =>
            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
          );
          setCountryOptions(optionData ?? []);
        } else {
          setCountryOptions([]);
        }
      } catch (error) {
        setCountryOptions([]);
      }
    };

    getCountryOptions();
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (
      callSearchJobsAPI.hasFetched() &&
      callSearchJobsAPI.hasErrors() === false &&
      callSearchJobsAPI.data() &&
      callSearchJobsAPI.data().data
    ) {
      const formattedJobs = callSearchJobsAPI.data()?.data?.jobs.map((rec) => {
        const formattedRec = {
          ...rec,
        };
        // Need job location to compare group jobs based on location as well
        formattedRec.interstride_location = processJobLocationTime({
          country: formattedRec?.country,
          state: formattedRec?.state,
          city: formattedRec?.city,
          time: null,
        });
        return formattedRec;
      });

      // Compute formatted unique jobs and grouped jobs
      const { uniqueJobs } = extractUniqueAndGroupJobs(
        formattedJobs,
        [],
        "search"
      );

      setJobs([...jobs, ...uniqueJobs].slice(0, 6));
      setShowLoader(false);
    } else if (
      callSearchJobsAPI.hasFetched() &&
      callSearchJobsAPI.hasErrors() === true
    ) {
      setJobs([]);
      setShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSearchJobsAPI.isFetching()]);

  useEffect(() => {
    if (
      callSearchTopEmployerJobsAPI.hasFetched() &&
      callSearchTopEmployerJobsAPI.hasErrors() === false &&
      callSearchTopEmployerJobsAPI.data() &&
      callSearchTopEmployerJobsAPI.data().data
    ) {
      const formattedJobs = callSearchTopEmployerJobsAPI
        .data()
        .data.jobs.map((rec) => {
          const formattedRec = {
            ...rec,
            ...rec.api_response,
          };
          delete formattedRec.api_response;

          // Need job location to compare group jobs based on location as well
          formattedRec.interstride_location = processJobLocationTime({
            country: formattedRec?.country,
            state: formattedRec?.state,
            city: formattedRec?.city,
            time: null,
          });

          return formattedRec;
        });

      // Compute formatted unique jobs and grouped jobs
      const { uniqueJobs } = extractUniqueAndGroupJobs(
        formattedJobs,
        [],
        "search"
      );

      setJobs([...jobs, ...uniqueJobs].slice(0, 6));
      setShowLoader(false);
    } else if (
      callSearchTopEmployerJobsAPI.hasFetched() &&
      callSearchTopEmployerJobsAPI.hasErrors() === true
    ) {
      setJobs([]);
      setShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSearchTopEmployerJobsAPI.isFetching()]);

  const handleChange = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
    applyFilters({ [name]: value });
  };

  const handleSearchChange = debounce(
    (value) => handleChange(value, "keyword"),
    1000
  );

  const applyFilters = (filters = {}) => {
    let {
      keyword = "",
      country = "us",
      search_type = "top_1000_employers",
    } = { ...state, ...filters };

    const defaultParams = {
      job_region: country && country === "us" ? "us" : "international",
      search: keyword === null ? "" : keyword,
      country,
      visa:
        country !== null && country === "us" && search_type !== null
          ? search_type
          : "",
    };

    setJobs([]);
    setShowLoader(true);

    if (
      defaultParams.country === "us" &&
      (defaultParams.visa === "top_500_employers" ||
        defaultParams.visa === "top_1000_employers" ||
        defaultParams.visa === "cap_exempt" ||
        defaultParams.visa === "sponsered_last_year" ||
        defaultParams.visa === US_VISA_LIST_DOMESTIC_VIEW[0].value ||
        defaultParams.visa === US_VISA_LIST_DOMESTIC_VIEW[1].value ||
        defaultParams.visa === US_VISA_LIST_DOMESTIC_VIEW[2].value)
    ) {
      refreshSearchTopEmployerJobsAPI(token, { ...defaultParams });
    } else {
      refreshSearchJobsAPI(token, { ...defaultParams });
    }
  };

  const onJobClick = (e) => {
    e && e.preventDefault();
    widgetRedirection("jobs");
  };

  const renderSearchResult = (data, i) => {
    const numberOfPetitions = data?.no_of_petitions || 0;
    return (
      <Grid item xs={12} key={`job-${i}`}>
        <CardCustom>
          <div className="search-item">
            <div className="avtar">
              <InitialAvatar
                variant="rounded"
                title={data.company}
                index={i}
                initialCount={1}
              />
            </div>
            <div className="content">
              <div className="header-primary flex">
                {data.job_title && (
                  <span className="inline-title">
                    {capitalizeFirstLetter(data.job_title)}
                  </span>
                )}

                {/* If any of the child jobs will be sponsored then grouped parent will display tag */}
                {data?.sponsored_last_year && (
                  <Tooltip title={"Sponsored last year"} placement="bottom">
                    <span className="tag-item cursor-pointer">
                      Sponsored last year{" "}
                    </span>
                  </Tooltip>
                )}
              </div>
              {numberOfPetitions > 0 ? (
                <Tooltip
                  title={`2023 H-1B Petitions: ${numberOfPetitions}`}
                  placement="bottom-start"
                >
                  <div className="sub-header-primary">{data.company}</div>
                </Tooltip>
              ) : (
                <div className="sub-header-primary">{data.company}</div>
              )}
              <div className="text-grey">
                {data?.formatted_location && data?.formatted_relative_time ? (
                  <span>
                    {data?.formatted_location}
                    {" | "}
                    {data?.formatted_relative_time}
                  </span>
                ) : (
                  <span>
                    {processJobLocationTime({
                      city: data?.city,
                      state: data?.state,
                      time: data?.date ?? data?.publish_date,
                    })}
                  </span>
                )}
              </div>
            </div>
            <div className="flex align-items-center">
              <div className={`card-actions-wrapper`}>
                <a
                  className="nlink-btn nbg-widget"
                  href={"/#"}
                  aria-label={`Apply for ${data.job_title}`}
                  onClick={onJobClick}
                >
                  Apply
                </a>
              </div>
              <StarIcon
                aria-label={`Add ${data.job_title} to saved jobs`}
                onClick={onJobClick}
                className={`card-actions-wrapper`}
              />
            </div>
          </div>
        </CardCustom>
      </Grid>
    );
  };

  return (
    <JobsStyles>
      <div className="widget-jobs-tab">
        <div className={`collapsible-view ${openFilter ? "open" : ""}`}>
          <h2 className="title">
            Find your next job
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                toggleFilter(!openFilter);
              }}
              className="collapse-icon"
              aria-label="Toggle filter"
            >
              {openFilter ? (
                <img src={FilledFilterIcon} alt="Filter Icon" />
              ) : (
                <img src={FilterIcon} alt="Filter Icon" />
              )}
            </a>
          </h2>

          <div className="jobs-filter-wrapper">
            <TextboxCustom
              name="search"
              type="text"
              aria-labelledby="search-keyword-label"
              placeholder="Search keyword"
              label=""
              lableaignment="left"
              onChange={(e) => handleSearchChange(e.target.value)}
            />
            <DropdownCustom
              name="country"
              label=""
              placeholder="Select country"
              justifyContent="left"
              options={countryOptions}
              value={countryOptions.find((rec) => rec.value === state.country)}
              onChange={(e) => handleChange(e.value, "country")}
            />
            {/* Enable only for US */}
            {state.country && state.country === "us" && (
              <DropdownCustom
                name="employer"
                label=""
                placeholder="Select employer"
                justifyContent="left"
                options={US_VISA_LIST}
                value={US_VISA_LIST.find(
                  (rec) => rec.value === state.search_type
                )}
                onChange={(e) => handleChange(e.value, "search_type")}
              />
            )}
          </div>
        </div>

        <div className="jobs-list-wrapper">
          {showLoader ? (
            <JobsListingEffect count={5} />
          ) : jobs && !isEmpty(jobs) ? (
            <>
              {jobs.map((job, i) => {
                return renderSearchResult(job, i);
              })}
              <WidgetOverlay
                title="Get access to all jobs on Interstride"
                route_type="jobs"
              />
            </>
          ) : (
            <EmptyListWithImage
              title={`Looks like there are no jobs with this search.`}
              subTitle={`Try removing some filters to expand your search and get more results.`}
              className={"default-empty-cover"}
            />
          )}
        </div>
      </div>
    </JobsStyles>
  );
};

export default Jobs;
