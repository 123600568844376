import React, { useState } from "react";
import SearchFilterStyles from "./SearchFilterStyles";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import Textbox from "../../Textbox/TextboxCustom";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import CardCustom from "../../CardCustom/CardCustom";
import DropdownCustom from "../../DropdownCustom/DropdownCustom";
import {
  US_VISA_LIST,
  US_VISA_LIST_DOMESTIC_VIEW,
  US_STATE_OPTIONS,
  JOB_POSITION_OPTIONS,
  JOB_DATE_POSTED_OPTIONS,
  JOB_SEARCH_TYPE,
  JOB_DEGREE_OPTIONS,
  JOB_EXPERIENCE_OPTIONS,
} from "../../../constant/mockdata";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import usePersistState from "../../../state/usePersistState";
import FilterIcon from "../../../assets/svg/filter-Icon.svg";
import FilledFilterIcon from "../../../assets/svg/filled-filter-Icon.svg";
import QuestionIcon from "../../QuestionIcon/QuestionIcon";

const SearchFilter = ({
  filter = {},
  setFilter,
  visaFilter = true,
  onClearAllClick,
  handleSaveSearchClick = () => {},
  ...props
}) => {
  const [showAllFilters, setShowAllFilters] = useState(true);
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const [openFilter, toggleFilter] = useState(false);
  const { jobsCountryList, users = {} } = usePersistState();
  const student_view_preference =
    users?.student_view_preference || "international";

  const {
    search,
    country,
    state,
    city,
    visa,
    job_type,
    publish_date,
    job_search_type = "approx",
    degree,
    experience,
  } = filter;

  const searchType = country && country === "us" ? "us" : "international";

  const [showVisaPopover, setVisaPopover] = useState(null);
  const openVisa = Boolean(showVisaPopover);
  const idVisa = openVisa ? "simple-visa-popover" : undefined;

  const [showKeywordPopover, setKeywordPopover] = useState(null);
  const openKeyword = Boolean(showKeywordPopover);
  const idKeyword = openKeyword ? "simple-keyword-popover" : undefined;

  const [showCityPopover, setCityPopover] = useState(null);
  const openCity = Boolean(showCityPopover);
  const idCity = openCity ? "simple-city-popover" : undefined;

  const [showJobSearchTypePopover, setJobSearchTypePopover] = useState(null);
  const openJobSearchType = Boolean(showJobSearchTypePopover);
  const idJobSearchType = openJobSearchType
    ? "simple-job-search-popover"
    : undefined;

  const [showExperiencePopover, setExperiencePopover] = useState(null);
  const openExperience = Boolean(showExperiencePopover);
  const idExperience = openCity ? "simple-experience-popover" : undefined;

  const [showDegreePopover, setDegreePopover] = useState(null);
  const openDegree = Boolean(showDegreePopover);
  const idDegree = openCity ? "simple-degree-popover" : undefined;

  const handleClose = (type = "visa") => {
    type === "visa"
      ? setVisaPopover(null)
      : type === "keyword"
      ? setKeywordPopover(null)
      : type === "job_search_type"
      ? setJobSearchTypePopover(null)
      : type === "experience"
      ? setExperiencePopover(null)
      : type === "degree"
      ? setDegreePopover(null)
      : setCityPopover(null);
  };

  const handleClick = (event, type = "visa") => {
    event.preventDefault();
    type === "visa"
      ? setVisaPopover(event.currentTarget)
      : type === "keyword"
      ? setKeywordPopover(event.currentTarget)
      : type === "job_search_type"
      ? setJobSearchTypePopover(event.currentTarget)
      : type === "experience"
      ? setExperiencePopover(event.currentTarget)
      : type === "degree"
      ? setDegreePopover(event.currentTarget)
      : setCityPopover(event.currentTarget);
  };

  const applyCurrentFilters = () => {
    toggleFilter(false);
    props.applyFilters();
  };

  return (
    <SearchFilterStyles>
      <div
        className={`search-filter collapsible-view ${openFilter ? "open" : ""}`}
      >
        <h2 className="title-primary mb-15">
          Search & filter
          <Link
            to="/#"
            className="ngray-link"
            onClick={(e) => {
              e.preventDefault();
              setFilter({
                search: "",
                state: "",
                city: "",
                job_type: "",
                publish_date: "",
                country: visaFilter === true ? "us" : "",
                visa:
                  student_view_preference === "international"
                    ? "top_1000_employers"
                    : US_VISA_LIST_DOMESTIC_VIEW[0].value,
              });

              if (onClearAllClick) onClearAllClick();
            }}
          >
            Clear all
          </Link>
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              toggleFilter(!openFilter);
            }}
            className="collapse-icon"
          >
            {openFilter ? (
              <img src={FilledFilterIcon} alt="Filter Icon" />
            ) : (
              <img src={FilterIcon} alt="Filter Icon" />
            )}
          </a>
        </h2>
        <Grid container>
          <Grid item xs={12}>
            <CardCustom className="search-card">
              <div className="form-field-group">
                <label id="search-keyword-label" className="textbox-label">
                  Search keyword
                  <QuestionIcon
                    aria-label="Search keyword"
                    onClick={(e) => handleClick(e, "keyword")}
                  />
                </label>

                <Textbox
                  name="search"
                  type="text"
                  aria-labelledby="search-keyword-label"
                  placeholder="Enter skill or company"
                  label=""
                  lableaignment="left"
                  value={search}
                  onChange={(e) => setFilter({ search: e.target.value })}
                  onEnterKey={applyCurrentFilters}
                />
                <Popover
                  id={idKeyword}
                  open={openKeyword}
                  anchorEl={showKeywordPopover}
                  onClose={() => handleClose("keyword")}
                  className="popover-wrapper"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Typography>
                    Search for job titles and positions related to your major
                    and degree
                  </Typography>
                  {accessibilityHelp === "true" && (
                    <div className="close-button">
                      <ButtonCustom onClick={() => handleClose("keyword")}>
                        Got It!
                      </ButtonCustom>
                    </div>
                  )}
                </Popover>
              </div>
              <div className="form-field-group">
                <DropdownCustom
                  name="country"
                  label="Select country"
                  placeholder="Select country"
                  justifyContent="left"
                  options={jobsCountryList}
                  value={
                    country && country !== null
                      ? jobsCountryList.find((rec) => rec.value === country)
                      : ""
                  }
                  onChange={(e) => {
                    setFilter({ country: e.value, state: null });
                  }}
                />
              </div>
              {visaFilter === true && searchType && searchType === "us" && (
                <div className="form-field-group">
                  <label id="visa-label" className="textbox-label">
                    Companies
                    <QuestionIcon
                      aria-label="Companies"
                      onClick={(e) => handleClick(e, "visa")}
                    />
                  </label>
                  <Popover
                    id={idVisa}
                    open={openVisa}
                    anchorEl={showVisaPopover}
                    onClose={() => handleClose("visa")}
                    className="popover-wrapper"
                    aria-labelledby="visa-label"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {student_view_preference === "international" ? (
                      <Typography>
                        <b>Top 500 H-1B:</b> Top 500 H-1B sponsoring employers
                        from the past year responsible for hiring early talent.
                        High probability of sponsorship from the largest H-1B
                        employers. These employers may have global offices to
                        relocate you internationally if H-1B lottery is
                        unsuccessful.
                        <br />
                        <br />
                        <b>Top 1000 H-1B:</b> Top 1000 H-1B sponsoring employers
                        from the past year responsible for hiring early talent.
                        High probability of sponsorship, but includes smaller
                        companies with limited sponsorship history.
                        <br />
                        <br />
                        <b>Sponsored last year:</b> The jobs were sponsored last
                        year. Highest probability of sponsorship.
                        <br />
                        <br />
                        <b>Cap-exempt:</b> Employers exempt from the H-1B
                        lottery process. High probability of sponsorship.
                        <br />
                        <br />
                        <b>All companies:</b> Relevant for domestic students.
                        Low probability of sponsorship for international
                        students.
                      </Typography>
                    ) : (
                      <Typography>
                        <b>Fortune 500:</b> The Fortune 500 is a list of the
                        largest 500 companies in the United States ranked by
                        total revenue.
                        <br />
                        <br />
                        <b>Fortune 1000:</b> The Fortune 1000 is a list of the
                        largest 1000 companies in the United States ranked by
                        total revenue.
                        <br />
                        <br />
                        <b>Crunchbase Unicorn:</b> The Crunchbase Unicorn Board
                        is a curated list of the most valuable private companies
                        in the world. Powered by Crunchbase’s comprehensive
                        data, this unicorn company list is updated as companies
                        are valued at $1 billion or more in a new funding round.
                      </Typography>
                    )}
                    {accessibilityHelp === "true" && (
                      <div className="close-button">
                        <ButtonCustom onClick={() => handleClose("visa")}>
                          Got It!
                        </ButtonCustom>
                      </div>
                    )}
                  </Popover>
                  <DropdownCustom
                    name="visa"
                    justifyContent="left"
                    options={
                      student_view_preference === "international"
                        ? US_VISA_LIST
                        : US_VISA_LIST_DOMESTIC_VIEW
                    }
                    value={(student_view_preference === "international"
                      ? US_VISA_LIST
                      : US_VISA_LIST_DOMESTIC_VIEW
                    ).find((rec) => rec.value === visa)}
                    onChange={(e) => setFilter({ visa: e.value })}
                    ariaLabelledby="visa-label"
                  />
                </div>
              )}

              {showAllFilters && (
                <>
                  {job_type !== "internship" && (
                    <div className="form-field-group">
                      <label id="experience-label" className="textbox-label">
                        Experience
                        <QuestionIcon
                          aria-label="Experience"
                          onClick={(e) => handleClick(e, "experience")}
                        />
                      </label>
                      <Popover
                        id={idExperience}
                        open={openExperience}
                        anchorEl={showExperiencePopover}
                        onClose={() => handleClose("experience")}
                        className="popover-wrapper"
                        aria-labelledby="experience-label"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Typography>
                          Note that this filter will only show jobs when
                          required experience is explicitly stated in the job
                          description. It's a small subset of the total jobs on
                          the Interstride platform since many job listings do
                          not clearly state experience level in their job
                          description.
                          <br />
                          <br />
                          <b>Entry level :</b>{" "}
                          {`No work experience required or < 1 year`}
                          <br />
                          <br />
                          <b>Intermediate :</b> 2-5 years of work experience
                          <br />
                          <br />
                          <b>Experienced :</b> 5-10 years of work experience
                          <br />
                          <br />
                          <b>Advanced :</b> 10+ years of work experience
                        </Typography>
                        {accessibilityHelp === "true" && (
                          <div className="close-button">
                            <ButtonCustom
                              onClick={() => handleClose("experience")}
                            >
                              Got It!
                            </ButtonCustom>
                          </div>
                        )}
                      </Popover>
                      <DropdownCustom
                        name="experience"
                        placeholder="Experience"
                        label=""
                        justifyContent="left"
                        options={JOB_EXPERIENCE_OPTIONS}
                        value={
                          experience && experience !== null
                            ? JOB_EXPERIENCE_OPTIONS.find(
                                (rec) => rec.value === experience
                              )
                            : null
                        }
                        onChange={(e) => setFilter({ experience: e.value })}
                      />
                    </div>
                  )}

                  <div className="form-field-group">
                    <label id="degree-label" className="textbox-label">
                      Degree
                      <QuestionIcon
                        aria-label="Degree"
                        onClick={(e) => handleClick(e, "degree")}
                      />
                    </label>
                    <Popover
                      id={idDegree}
                      open={openDegree}
                      anchorEl={showDegreePopover}
                      onClose={() => handleClose("degree")}
                      className="popover-wrapper"
                      aria-labelledby="degree-label"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Typography>
                        Note that this filter will only show jobs when the
                        required degree is explicitly stated in the job
                        description. It's a small subset of the total jobs on
                        the Interstride platform since many job listings do not
                        clearly state degree requirements in their job
                        description.
                      </Typography>
                      {accessibilityHelp === "true" && (
                        <div className="close-button">
                          <ButtonCustom onClick={() => handleClose("degree")}>
                            Got It!
                          </ButtonCustom>
                        </div>
                      )}
                    </Popover>
                    <DropdownCustom
                      name="degree"
                      placeholder="Degree"
                      label=""
                      justifyContent="left"
                      options={JOB_DEGREE_OPTIONS}
                      value={
                        degree && degree !== null
                          ? JOB_DEGREE_OPTIONS.find(
                              (rec) => rec.value === degree
                            )
                          : null
                      }
                      onChange={(e) => setFilter({ degree: e.value })}
                    />
                  </div>

                  <div className="form-field-group">
                    <DropdownCustom
                      name="job_type"
                      label="Position"
                      justifyContent="left"
                      options={JOB_POSITION_OPTIONS}
                      value={
                        job_type && job_type !== null
                          ? JOB_POSITION_OPTIONS.find(
                              (rec) => rec.value === job_type
                            )
                          : null
                      }
                      onChange={(e) => setFilter({ job_type: e.value })}
                    />
                  </div>

                  {visaFilter === true && searchType && searchType === "us" && (
                    <div className="form-field-group">
                      <DropdownCustom
                        name="state"
                        label="State"
                        justifyContent="left"
                        options={
                          student_view_preference === "international"
                            ? US_VISA_LIST
                            : US_VISA_LIST_DOMESTIC_VIEW
                        }
                        value={
                          state && state !== null
                            ? (student_view_preference === "international"
                                ? US_VISA_LIST
                                : US_VISA_LIST_DOMESTIC_VIEW
                              ).find((rec) => rec.value === state)
                            : null
                        }
                        onChange={(e) => {
                          setFilter({ state: e.value });
                        }}
                      />
                    </div>
                  )}
                </>
              )}

              {showAllFilters && (
                <>
                  {visaFilter !== true && props.searchType === "us" && (
                    <div className="form-field-group">
                      <DropdownCustom
                        name="state"
                        label="State"
                        justifyContent="left"
                        options={US_STATE_OPTIONS}
                        value={
                          state && state !== null
                            ? US_STATE_OPTIONS.find(
                                (rec) => rec.value === state
                              )
                            : null
                        }
                        onChange={(e) => {
                          setFilter({ state: e.value });
                        }}
                      />
                    </div>
                  )}

                  <div className="form-field-group">
                    <label id="city-label" className="textbox-label">
                      City
                      <QuestionIcon
                        aria-label="City"
                        onClick={(e) => handleClick(e, "city")}
                      />
                    </label>
                    <Popover
                      id={idCity}
                      open={openCity}
                      anchorEl={showCityPopover}
                      onClose={() => handleClose("city")}
                      className="popover-wrapper"
                      aria-labelledby="city-label"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Typography>
                        Type the name of the city. Type <b>“Remote”</b> for
                        remote positions.
                      </Typography>
                      {accessibilityHelp === "true" && (
                        <div className="close-button">
                          <ButtonCustom onClick={() => handleClose("city")}>
                            Got It!
                          </ButtonCustom>
                        </div>
                      )}
                    </Popover>
                    <Textbox
                      name="city"
                      type="text"
                      placeholder="Enter City or Remote"
                      label=""
                      lableaignment="left"
                      value={city ? city : ""}
                      onChange={(e) => setFilter({ city: e.target.value })}
                      onEnterKey={applyCurrentFilters}
                    />
                  </div>
                  <div className="form-field-group">
                    <DropdownCustom
                      name="publish_date"
                      label="Date posted"
                      justifyContent="left"
                      options={JOB_DATE_POSTED_OPTIONS}
                      value={
                        publish_date && publish_date !== null
                          ? JOB_DATE_POSTED_OPTIONS.find(
                              (rec) => rec.value === publish_date
                            )
                          : null
                      }
                      onChange={(e) => setFilter({ publish_date: e.value })}
                    />
                  </div>
                </>
              )}

              <div className="form-field-group">
                <label className="textbox-label" id="search-results-label">
                  Search results
                  <QuestionIcon
                    aria-label="Search results"
                    onClick={(e) => handleClick(e, "job_search_type")}
                  />
                </label>
                <Popover
                  id={idJobSearchType}
                  open={openJobSearchType}
                  anchorEl={showJobSearchTypePopover}
                  onClose={() => handleClose("job_search_type")}
                  className="popover-wrapper"
                  aria-labelledby="search-results-label"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Typography>
                    <b>{JOB_SEARCH_TYPE[0]?.label} :</b>{" "}
                    {JOB_SEARCH_TYPE[0]?.tooltip}
                    <br />
                    <br />
                    <b>{JOB_SEARCH_TYPE[1]?.label} :</b>{" "}
                    {JOB_SEARCH_TYPE[1]?.tooltip}
                  </Typography>
                  {accessibilityHelp === "true" && (
                    <div className="close-button">
                      <ButtonCustom
                        onClick={() => handleClose("job_search_type")}
                      >
                        Got It!
                      </ButtonCustom>
                    </div>
                  )}
                </Popover>
                <DropdownCustom
                  name="visa"
                  label=""
                  placeholder="Search results"
                  justifyContent="left"
                  options={JOB_SEARCH_TYPE}
                  value={JOB_SEARCH_TYPE.find(
                    (rec) => rec.value === job_search_type
                  )}
                  onChange={(e) => setFilter({ job_search_type: e.value })}
                />
              </div>

              <div className="text-center mt-20">
                <a
                  href="/#"
                  className={`nlink-secondary-dark`}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowAllFilters(!showAllFilters);
                  }}
                >
                  {showAllFilters ? "Hide filters" : "Filters"}
                </a>
              </div>
              <div className="searchBtn mt-20 mb-20">
                <ButtonCustom onClick={applyCurrentFilters} width="250">
                  Search
                </ButtonCustom>
              </div>
              {props.showSaveSearch && (
                <div className="text-center">
                  <Link
                    to="/#"
                    className="nlink-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSaveSearchClick();
                    }}
                  >
                    Save search
                  </Link>
                </div>
              )}
            </CardCustom>
          </Grid>
        </Grid>
      </div>
    </SearchFilterStyles>
  );
};
export default SearchFilter;
